.Charts__toggle {
	background-color:	#ddd;
	border-radius	:	3px;
	box-shadow		:	1px 1px 4px 0 #000;
	cursor			:	pointer;
	font-size		:	30px;
	line-height		:	30px;
	padding			:	3px 3px;
	z-index			:	1;
}

.Charts {
	background-color	:	RGBa(0,0,0,0.6);
	margin		:	auto;
	overflow	:	scroll;
	position	:	absolute;
	top			:	0;
	right		:	0;
	bottom		:	0;
	left		:	0;
}

.Charts > div {
	box-shadow			:	1px 0px 5px 0px #333;
	margin-bottom		:	40px;
}
