.map_marker_info ul {
	margin	:	0;
	padding	:	0 12px 12px 17px;
}
.map_marker_info .text_muted {
	color	:	#aaa;
}

.map_marker_info .pill {
	box-shadow		:	1px 1px 4px 0 #555;
	border-radius	:	5px;
	display			:	inline-block;
	height			:	8px;
	width			:	20px;
	margin-left		:	4px;
	vertical-align	:	middle;
}